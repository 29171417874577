

















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CorrectGuide extends Vue {
  private mounted() {
    Vue.prototype.$logger.info('-- CorrectGuide mounted')
  }

  private get correctResult(): any[] {
    return this.$store.getters['drills/correctResult']
  }

  private correctCount(): number {
    return [].concat(...this.correctResult).filter((x: boolean) => x === true).length
  }

  private incorrectCount(): number {
    return [].concat(...this.correctResult).filter((x: boolean) => x === false).length
  }

  /**
   * 授業種別コード
   */
  private get classCategoryCode(): string {
    return Array.isArray(this.$route.query.classCategoryCode)
      ? this.$route.query.classCategoryCode[0] || ''
      : this.$route.query.classCategoryCode || ''
  }

  /**
   * 授業種別名
   */
  private get classCategoryName(): string {
    let result = ''
    switch (this.classCategoryCode) {
      case 'EN':
        result = '演習'
        break
      case 'SY':
        result = '宿題'
    }

    return result
  }

  private get guideText(): string {
    let result = ''
    switch (this.classCategoryCode) {
      case 'EN':
      case 'SY':
        result = '結果一覧'
        break
    }

    return result
  }
}
