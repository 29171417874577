





import { Component, Mixins, Vue } from 'vue-property-decorator'
import Title from '@/components/modules/drills/atoms/Title.vue'
import ResultGuide from '@/components/modules/drills/atoms/ResultGuide.vue'
import PredictedScore from '@/components/modules/drills/atoms/PredictedScore.vue'
import Correct from '@/components/modules/drills/organisms/Correct.vue'
import Transition from '@/components/modules/drills/organisms/Transition.vue'
import AutoResult from '@/components/modules/drills/organisms/AutoResult.vue'

import DrillsConfig from '@/mixins/drills/Config'
import DrillWebApi from '@/mixins/drills/WebApi'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import VueRouter from 'vue-router'

Component.registerHooks(['beforeRouteUpdate'])

@Component({
  components: {
    Title,
    ResultGuide,
    PredictedScore,
    Transition,
    Correct,
    AutoResult,
  },
})
export default class DrillResult extends Mixins(DrillWebApi, LessonStudent) {
  private get isReport() {
    return this.$route.path.includes('report')
  }

  private drillModules = DrillsConfig.drillModules

  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode as string
  }

  protected get lessonId() {
    return this.$store.getters['lessonActionCable/lessonId']
  }

  /**
   * 授業種別コード
   *
   * @return 授業種別コード
   */
  private get classCategoryCode(): string {
    return Array.isArray(this.$route.query.classCategoryCode)
      ? this.$route.query.classCategoryCode[0] || ''
      : this.$route.query.classCategoryCode || ''
  }

  /**
   * カリキュラムモードコード
   *
   * @return カリキュラムモードコード
   */
  private get curriculumModeCode(): string {
    return Array.isArray(this.$route.query.curriculumModeCode)
      ? this.$route.query.curriculumModeCode[0] || ''
      : this.$route.query.curriculumModeCode || ''
  }

  /**
   * 教科によって縦画面か横画面か読み込むモジュールを変更する（恐らく定期テストや一問一答が入ってくると名称を変更する）
   */
  private get drillModulesByClassCategoryCode() {
    Vue.prototype.$logger.info('-- Drill drillModulesByClassCategoryCode')

    // ベースはRESULT_ALLを利用
    let result = this.drillModules.RESULT_ALL

    // 条件によって違うモジュールを読み込むように上書き
    const moduleFunc = {
      // 通常学習の演習の全結果表示の場合は理解度モジュールを表示する
      result_all_TJ_EN_: () => {
        return this.drillModules.RESULT_ALL_PREDICTED_SCORE
      },

      // 定期テストの演習の全結果表示の場合は理解度モジュールを表示する
      result_all_TK_EN_: () => {
        return this.drillModules.RESULT_ALL_PREDICTED_SCORE
      },

      // 講習会の演習の全結果表示の場合は理解度モジュールを表示する
      result_all_KS_EN_: () => {
        return this.drillModules.RESULT_ALL_PREDICTED_SCORE
      },

      // 補助教材（一問一答）
      result_all_HJ_HT_OO: () => {
        return this.drillModules.RESULT_AUTO
      },
    }

    // 上記で設定したモード名の組み合わせのメソッドが存在する場合は実行する
    let _classMode: string = this.classMode() as string
    if (this.isReport) {
      _classMode = (this.$route.query.classMode as string) || ''
    }
    const moduleFuncKey = `${this.$route.query.pageType}_${_classMode}_${this.classCategoryCode}_${this.curriculumModeCode}`
    if (moduleFunc[moduleFuncKey]) result = moduleFunc[moduleFuncKey]()
    //remove component Transition
    const resultDrill = [...result]
    if (this.isReport) {
      resultDrill.includes('Transition') && resultDrill.pop()
    }
    return resultDrill
  }

  private setNoticeCurrentPageText() {
    let result = ''

    if (this.classCategoryCode === 'EN') {
      result = '演習結果確認中'
    } else if (this.classCategoryCode === 'RK') {
      result = '理解度テスト結果確認中'
    } else if (this.classCategoryCode === 'KK') {
      result = '確認テスト結果確認中'
    } else if (this.classCategoryCode === 'SY') {
      result = '宿題結果確認中'
    }

    // 補助教材選択中
    if (this.curriculumModeCode === 'OO') {
      result = '補助教材テスト結果確認中'
    }

    this.$store.commit('drills/setNoticeCurrentPageText', result)
  }

  protected created(): void {
    this.setNoticeCurrentPageText()
  }

  protected async mounted(): Promise<any> {
    Vue.prototype.$loading.start()

    Vue.prototype.$logger.info('-- DrillResult mounted')

    switch (this.$route.query.pageType) {
      case 'result_all':
        // 自己採点に関連する情報を取得するAPIを呼ぶ
        await this.startResultAllApi({
          lessonId: this.lessonId,
          resultDrillId: Number(this.$route.query.resultDrillId),
        })

        break
      case 'result_unit':
        // 自己採点に関連する情報を取得するAPIを呼ぶ
        await this.startResultUnitApi({
          lessonId: this.lessonId,
          resultDrillId: Number(this.$route.query.resultDrillId),
          page: Number(this.$route.query.page),
        })

        break
    }

    Vue.prototype.$loading.complete()
  }
}
