















































import { Component, Prop, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioOkNg from '@/components/molecules/RadioOkNg.vue'
import ImageExpansion from '@/components/modules/drills/molecules/ImageExpansion.vue'
import ScrollGuide from '@/components/modules/drills/atoms/ScrollGuide.vue'

@Component({
  components: {
    ButtonBase,
    RadioOkNg,
    ImageExpansion,
    ScrollGuide,
  },
})
export default class DrillAssistResult extends Vue {
  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode

  private get processed(): boolean {
    return this.$store.getters['drills/processed']
  }

  private mounted() {
    Vue.prototype.$logger.log('-- mounted DrillAssistResult')
  }

  private get classMode() {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private get isVertical() {
    // 国語の場合はtrue、ただし入試対策モードの場合はfalseになる
    return this.subjectCode === 'ko' && this.classMode !== 'NY'
  }

  /**
   * Web APIを通して取得した画像URLを取得
   * [{d: '...', m: '...', j: '...'}, ...]
   *
   * @return 画像URL群
   */
  private get imageUrls(): any[] {
    return this.$store.getters['drills/imageUrls']
  }

  private get questionNumber(): number[] {
    return this.$store.getters['drills/questionNumber']
  }

  private get correctInput(): any[] {
    return this.$store.getters['drills/correctInput']
  }

  private get correctResult(): any[] {
    return this.$store.getters['drills/correctResult']
  }

  private get sProblemIdForQuestions(): string {
    return this.$store.getters['drills/sProblemIdForQuestions']
  }

  /**
   * 授業種別コード
   *
   * @return 授業種別コード
   */
  private get classCategoryCode(): string {
    return Array.isArray(this.$route.query.classCategoryCode)
      ? this.$route.query.classCategoryCode[0] || ''
      : this.$route.query.classCategoryCode || ''
  }

  private updateCorrectInput(event: boolean, pageIndex: number, questionIndex: number) {
    Vue.prototype.$logger.log(`updateCorrectInput ${event} ${pageIndex} ${questionIndex}`)

    this.$store.commit('drills/setCorrectInputDetail', {
      value: event,
      pageIndex: pageIndex,
      questionIndex: questionIndex,
    })
  }

  /**
   * 該当ページの問題が大問かどうか取得
   *
   * @param pageIndex ページ番号
   * @return 大問かどうかの真偽値
   */
  private isLProblem(pageIndex: number): boolean {
    return this.$store.getters['drills/isLProblems'][pageIndex]
  }

  /**
   * 該当ページの大問毎の設問数
   *
   * @return 設問数
   */
  private questionNumberWithSProblem(pageIndex: number): number[] {
    return this.$store.getters['drills/questionNumberWithSProblem'][pageIndex]
  }

  private pageQuestionStartNumber(end: number, pageIndex: number): number {
    return (
      this.questionNumberWithSProblem(pageIndex)
        .slice(0, end)
        .reduce((sum, element) => sum + element, 0) + 1
    )
  }

  /**
   * 現在のページ番号
   *
   * @param pageIndex imageUrls配列のindex番号
   * @return ページ番号
   */
  private currentPage(pageIndex: number): number {
    return this.$store.getters['drills/drillData'].data[pageIndex].page
  }

  /**
   * 問題と問題の間に線を入れるためのスタイル
   */
  private contentStyles(pageIndex: number): { [key: string]: string } {
    if (pageIndex === 0) return {}
    return {
      'border-top': '1px solid #d8d8d8',
    }
  }

  /**
   * 選択または入力したテキスト情報
   *
   * @param pageIndex ページ番号
   * @return テキストの配列
   */
  private inputSelectText(pageIndex: number): number {
    return this.$store.getters['drills/inputSelectText'][pageIndex]
  }

  /**
   * 正解のテキスト情報
   *
   * @param pageIndex ページ番号
   * @return テキストの配列
   */
  private answerText(pageIndex: number): number {
    return this.$store.getters['drills/answerText'][pageIndex]
  }
}
