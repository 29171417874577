








import { Component, Vue } from 'vue-property-decorator'
const maxLeft = 75

@Component
export default class PredictedScore extends Vue {
  private mounted() {
    Vue.prototype.$logger.info('-- PredictedScore mounted')
  }

  // 理解度の下限
  private get minPredictedScore(): number {
    return this.$store.getters['drills/predictedScoreMin']
  }

  // 理解度の上限
  private get maxPredictedScore(): number {
    return this.$store.getters['drills/predictedScoreMax']
  }

  private get styles(): { [key: string]: string } {
    const startPosition = (this.minPredictedScore + this.maxPredictedScore) / 2 - 12.5
    let left
    if (startPosition < 0) {
      left = 0
    } else if (startPosition > maxLeft) {
      left = maxLeft
    } else {
      left = startPosition
    }
    return {
      left: `${left}%`,
    }
  }
}
